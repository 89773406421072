/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';
import Layout from '../components/layout';
import _DATA from '../data/thinking';
import QuoteSection from '../components/QuoteSection';
import Quote, { StyledQuoteBlock, QuoteColorBlocks } from '../components/Quote';
// import QuoteGrid from '../components/Grids/Quote';
import ThinkingCardSlider from '../components/ThinkingCardSlider';
import ThinkingToolbox from '../components/ThinkingToolbox';
import ThinkingProcess from '../components/ThinkingProcess';
import ThinkingHero from '../components/ThinkingHero';
import ThinkingTextSection from '../components/ThinkingTextSection';

const ThinkingPage = () => {
  const { hero, chart, quote, process, processChart, toolbox } = _DATA;

  return (
    <Layout>
      {/* Hero */}
      <ThinkingHero />

      <ThinkingTextSection />

      {/* Process */}
      <ThinkingProcess />

      {/* TOOLBOX */}
      <ThinkingToolbox />

      {/* Quote */}
      <QuoteSection copy={quote.quote} author={quote.author} darkMode scale="tertiary" />
    </Layout>
  );
};

export default ThinkingPage;
