/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import React from 'react'
import { TextSectionGeneric } from './TextSections'
import _DATA from '../data/thinking'

const ThinkingToolbox = () => {
  const { toolbox } = _DATA
  return (
    <section
      sx={{
        position: 'relative',
      }}
    >
      <TextSectionGeneric title={toolbox.title}>
        {toolbox.blocks.map((x, i) => (
          <div
            key={i}
            sx={{
              img: {
                maxWidth: '50px',
              },
            }}
          >
            <div>
              <Styled.h3>{x.headline}</Styled.h3>
              <Styled.p sx={{ color: 'bodyText' }}>{x.copy}</Styled.p>
            </div>
            {/* one glyph */}
            {x.glyph && <img src={x.glyph} alt={x.headline} />}
            {/* multiGlyph */}
            {x.glyphs &&
              x.glyphs.map((y, j) => (
                <img
                  src={y}
                  key={j}
                  sx={{ mr: '1em' }}
                  alt={`${x.headline} - ${j}`}
                />
              ))}
          </div>
        ))}
      </TextSectionGeneric>
    </section>
  )
}

export default ThinkingToolbox
