/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';
import { PhiloSubGrid, TextSectionGeneric } from './TextSections';
import _DATA from '../data/thinking';

const ThinkingTextSection = () => {
  const { theNeed } = _DATA;
  const {title, subheadline, blocks} = theNeed;
  return (
    <TextSectionGeneric title={title}>
      <div
        sx={{
          gridColumn: 'span 2',
          p: {
            color: 'bodyText',
          },
        }}
      >
        <Styled.p sx={{ mb: '55px' }}>{subheadline}</Styled.p>
        <PhiloSubGrid>
          {blocks.map((x, i) => (
            <div key={i}>
              <Styled.h3>{x.headline}</Styled.h3>
              <Styled.p sx={{ color: 'inherit' }}>{x.copy}</Styled.p>
            </div>
          ))}
        </PhiloSubGrid>
      </div>
    </TextSectionGeneric>
  );
};

export default ThinkingTextSection