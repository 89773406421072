/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import media from './media';
import { Arrowhead } from '../assets/VectorAssets';

const ArrowBorder = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  /* width of arrowhead */
  left: 24px;
  border: ${props => `4px solid ${props.theme.colors.text}`};
  border-left: none;
`;

const ArrowBorderBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: ${props => `4px solid ${props.theme.colors.text}`};
`;

const StyledBorderArrowGuts = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .arrow--top {
    transform: translateY(calc(-50% + 2px));
  }

  .arrow--bottom {
    /* half - minus half width of border */
    transform: translateY(calc(50% - 2px));
  }
`;

const BorderArrowGuts = ({ returnArrow }) => (
  <StyledBorderArrowGuts>
    <Arrowhead className="arrow--top" />
    {returnArrow && <Arrowhead className="arrow--bottom" />}
    {!returnArrow && <ArrowBorderBottom />}
    <ArrowBorder />
  </StyledBorderArrowGuts>
);

const BorderArrowWrapper = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
`;

export default BorderArrowGuts;
export { BorderArrowWrapper };
