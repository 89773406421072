/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import React from 'react'
import GenericWrapper from './Wrapper'
import _DATA from '../data/thinking'

const HeroTextLockup = ({ title, headline }) => (
  <div
    sx={{
      mb: '2rem',
      flexBasis: 'auto',
    }}
  >
    <Styled.h5>{title}</Styled.h5>
    <Styled.h2>{headline}</Styled.h2>
  </div>
)

const ThinkingHero = () => {
  const { hero, chart } = _DATA
  console.log('ThinkingHero')
  return (
    <section
      sx={{
        variant: 'invert',
      }}
    >
      <GenericWrapper
        sx={{
          height: '100%',
          position: 'relative',
          '@keyframes foo': {
            '0%': {
              opacity: '0',
            },
            '3%': {
              opacity: '1',
            },
            '20%': {
              opacity: '1',
            },
            '23%': {
              opacity: '0',
            },
            '100%': {
              opacity: '0',
            },
          },
          '> div': {
            p: '10rem 0 5rem',
            height: '100%',
            display: 'flex',
            flexDirection: ['column', 'column', 'column', 'row'],
            justifyContent: 'space-between',
            animationDuration: '12.5s',
            animationName: 'foo',
            animationIterationCount: 'infinite',
            '> img': {
              alignSelf: 'flex-end',
            },
          },
        }}
      >
        <div
          sx={{
            p: '10rem 0 5rem',
            height: '100%',
            display: 'flex',
            flexDirection: ['column', 'column', 'column', 'row'],
            justifyContent: 'space-between',
            // bg: 'debug',
          }}
        >
          <HeroTextLockup title={hero.title} headline={hero.headline} />
          <img src={hero.img} alt="Static Graphic" />
        </div>

        {chart.map((x, i) => (
          <div
            key={i}
            sx={{
              // absolute
              position: 'absolute',
              left: ['24px', '24px', 0],
              right: ['24px', '24px', 0],
              top: 0,
              // delay
              animationDelay: `${(i + 1) * 2.5}s`,
              opacity: 0,
            }}
          >
            <HeroTextLockup title={x.title} headline={x.headline} />
            <img src={x.img} alt="Static Graphic" />
          </div>
        ))}
      </GenericWrapper>
    </section>
  )
}

export default ThinkingHero
