/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import React, { useRef, useState } from 'react'
import Nuka from 'nuka-carousel'
import { useWindowSize } from 'react-use'
import styled from '@emotion/styled'
import GenericWrapper from './Wrapper'
import _DATA from '../data/thinking'
import { NukaArrows } from './ComposedCarousel'
import media from './media'

const ThinkingCardMatWrapper = styled.div`
  width: 2004px; /* 1920 feels wierd */
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  display: none;
  ${media.lg`
    display: block;
  `}
`

const ThinkingCardMat = styled.div`
  position: relative;
  height: 100%;

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    background: white;
    /* background: pink; */
    width: calc(50vw - (2004px / 2) + 1px);
  }
  &:before {
    right: 100%;
  }
  &:after {
    left: 100%;
  }
`

const ThinkingCard = styled.div`
  padding: 24px 32px 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  /* height: 100%; */
`

const ThinkingCardSlider = () => {
  const { process } = _DATA
  const { width, height } = useWindowSize()
  const visibleCardCount = width < 768 ? 1 : width < 1025 ? 2 : 3
  console.log('visibleCardCount', visibleCardCount)

  return (
    <section
      sx={{
        overflow: 'hidden',
        py: ['80px', '80px', '120px'],
        '.slider-frame': {
          overflow: 'visible!important',
        },
      }}
    >
      <GenericWrapper>
        <Styled.h2>{process.title}</Styled.h2>
        <div sx={{ position: 'relative' }}>
          {/* 
          Magic overlay for this design 
          This is gross. 
          But the design doesn't seem completely baked,
          and also this is quick. 

          Forgime me CSS Gods
          */}
          <ThinkingCardMatWrapper>
            <ThinkingCardMat />
          </ThinkingCardMatWrapper>

          <Nuka
            slidesToShow={visibleCardCount}
            wrapAround
            cellSpacing={16}
            // autoplay
            enableKeyboardControls
            // rip out default controls
            renderCenterLeftControls={() => null}
            renderCenterRightControls={() => null}
            renderBottomCenterControls={() => null}
            // add ours
            renderBottomLeftControls={NukaArrows}
            heightMode="max"
            // heightMode="current"
          >
            {process.blocks.map((block, i) => (
              <ThinkingCard
                sx={{
                  height: ['384px', '360px', '360px', '388px'],
                  bg: theme =>
                    `${
                      theme.colors.scale.secondary[
                        i % theme.colors.scale.secondary.length
                      ]
                    }`,
                }}
                key={i}
              >
                <div sx={{ mb: '2rem' }}>
                  <Styled.h2 sx={{ color: 'background' }}>
                    {block.headline}
                  </Styled.h2>
                  <Styled.p>{block.copy}</Styled.p>
                </div>
                {block.glyph && (
                  <img src={block.glyph} alt={block.headline} sx={{ mb: 0 }} />
                )}
              </ThinkingCard>
            ))}
          </Nuka>
        </div>
      </GenericWrapper>
    </section>
  )
}

export default ThinkingCardSlider
