/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import styled from '@emotion/styled';
import media from './media';

const ProcessGrid = styled.div`
  display: grid;
  grid-template-rows: repeat(5, 240px);
  grid-template-columns: minmax(auto, 240px) minmax(64px, auto);
  grid-row-gap: 40px;
  grid-template-areas:
    'block-1 arrows'
    'block-3 arrows'
    'block-2 arrows'
    'block-4 arrows'
    'block-5 arrows';

  > .show-lg {
    display: none;
  }

  ${media.lg`
    position: relative;
    width: 100%;
    display: grid;
    grid-template-rows: repeat(4, 240px);
    grid-template-columns: repeat(5, 1fr);
    /* wont resize down */
    /* grid-template-columns: repeat(5, 240px); */
    grid-row-gap: 0;
    grid-template-areas:
      'block-1 arrow-1 block-3 arrow-foo .'
      '. block-2 arrow-2 block-4 .'
      '. . . arrow-3 block-5'
      '. . . . .';
    > .show-lg {
      display: block;
    }
  `}
`;

export default ProcessGrid;
