/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import media from './media'
import GenericWrapper from './Wrapper'
import _DATA from '../data/thinking'

export const NewThreeColumnGridWrapper = styled(GenericWrapper)`
  padding-top: 80px;
  padding-bottom: 80px;
  display: grid;
  grid-template-columns: auto;
  border-color: inherit;
  position: relative;
  /* debug */
  /* background-color: rgba(255, 100, 0, 0.1); */

  ${media.md`
    padding-top: 80px;
    padding-bottom: 80px;
    /* has no ipad grid stuff */
  `}

  ${media.lg`
    padding-top: 120px;
    padding-bottom: 120px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-columns: 1.5fr 2fr 1.5fr;
    /* grid-gap: 55px; */
    > * {
      padding-right: 24px;
      /* debug */
      /* background-color: rgba(255, 100, 0, 0.1); */
    }
  `}
`

const ProcessTextBlock = ({ headline, copy }) => (
  <>
    <Styled.h3>{headline}</Styled.h3>
    <Styled.p>{copy}</Styled.p>
  </>
)

const ProcessTextSection = () => {
  const { processChart } = _DATA
  return (
    <NewThreeColumnGridWrapper
      sx={{
        pt: ['40px', '40px', '40px', '40px'],
        pb: [0, 0, 0, 0],
      }}
    >
      {/* show all of them; hide the first on desktop */}
      {processChart.blocks.map((x, i) => (
        <div
          key={i}
          sx={{
            h3: {
              color: 'tertiary',
              textTransform: 'lowercase',
            },
          }}
        >
          <ProcessTextBlock copy={x.copy} headline={x.headline} />
        </div>
      ))}
    </NewThreeColumnGridWrapper>
  )
}

export default ProcessTextSection
