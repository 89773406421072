/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import media from './media';
import GenericWrapper from './Wrapper';
import { ThreeColumnGridWrapper } from './TextSections';
import _DATA from '../data/thinking';
import { Arrowhead } from '../assets/VectorAssets';
import BorderArrow from './BorderArrow';
import ProcessArrowVector from '../assets/ProcessArrows';

export const ProcessArrowsMobile = () => (
  <div
    sx={{
      gridArea: 'arrows',
      position: 'relative',
      display: ['block', 'block', 'block', 'none'],
    }}
  >
    {/* Position the wrapper */}
    <div
      sx={{
        position: 'absolute',
        left: '0',
        width: '60%',
        height: `calc((240px * 1) + (40px * 1))`,
        top: `calc((240px * 2.5) + (40px * 2))`,
      }}
    >
      {/* Guts */}
      <BorderArrow returnArrow />
    </div>
    <div
      sx={{
        position: 'absolute',
        left: '0',
        width: '100%',
        height: `calc((240px * 4) + (40px * 4))`,
        top: `calc((240px * 0.5) + (40px * 0))`,
      }}
    >
      <BorderArrow returnArrow />
    </div>
    <div
      sx={{
        position: 'absolute',
        left: '0',
        width: '80%',
        height: `calc((240px * 2) + (40px * 2) + (24px * 2))`,
        top: `calc((240px * 1.5) + (40px * 1))`,
      }}
    >
      <BorderArrow />
    </div>
  </div>
);

export const ProcessDashedBorders = () => (
  <>
    <div
      className="show-lg"
      sx={{
        gridColumn: '1',
        gridRow: '2 / span 3',
        borderLeft: '1px dashed purple',
      }}
    />
    <div
      className="show-lg"
      sx={{
        gridColumn: '2',
        gridRow: '3 / span 2',
      }}
    >
      <span
        sx={{
          display: 'block',
          height: '100%',
          width: '50%',
          borderRight: '1px dashed purple',
        }}
      />
    </div>
    <div
      className="show-lg"
      sx={{
        gridColumn: '5',
        gridRow: '4',
        borderRight: '1px dashed purple',
      }}
    />
    <div
      className="show-lg"
      sx={{
        gridColumn: '4',
        gridRow: '3 / span 2',
      }}
    >
      <span
        sx={{
          display: 'block',
          height: '100%',
          width: '50%',
          borderRight: '1px dashed purple',
        }}
      />
    </div>
  </>
);

export const ProcessArrowsDesktopSVG = () => (
  <>
    <div
      className="show-lg"
      sx={{
        gridArea: 'arrow-1',
        gridArea: 'arrow-foo',
        position: 'relative',
        pointerEvents: 'none',
        '> div': {
          position: 'absolute',
        },
      }}
    >
      <div
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '271%',
          maxWidth: '634px',
          right: '40%',

          '> svg': {
            transform: 'scale(-1,1) translate(1.7%, 2.5%)',
            transform: 'scale(-1,1)',
            width: '100%',
            height: 'auto',
            '> g': {
              transform: 'translate(1.7%, 2.5%)',
            },
          },
        }}
      >
        <ProcessArrowVector arrow={1} />
      </div>
    </div>

    <div
      className="show-lg"
      sx={{
        gridArea: 'arrow-foo',
        position: 'relative',
        pointerEvents: 'none',
        '> div': {
          position: 'absolute',
        },
      }}
    >
      <div
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '60%',
          // bg: 'rgba(0,0,0,0.3)',

          '> svg': {
            // transform: 'scale(-1,1)',
            transform: 'scale(-1,1) translate(1.7%, 3.6%)',
            width: '100%',
            height: 'auto',
          },
        }}
      >
        <ProcessArrowVector arrow={2} />
      </div>
    </div>

    <div
      className="show-lg"
      sx={{
        gridArea: 'arrow-2',
        position: 'relative',
        pointerEvents: 'none',
        '> div': {
          position: 'absolute',
        },
      }}
    >
      <div
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: '60%',
          // bg: 'rgba(0,0,0,0.3)',

          '> svg': {
            transform: 'scale(1,-1)',
            // transform: 'scale(-1,1) translate(1.7%, 3.6%)',
            width: '100%',
            height: 'auto',
          },
        }}
      >
        <ProcessArrowVector arrow={2} />
      </div>
    </div>
    <div
      className="show-lg"
      sx={{
        gridArea: 'arrow-3',
        position: 'relative',
        pointerEvents: 'none',
        '> div': {
          position: 'absolute',
        },
      }}
    >
      <div
        sx={{
          position: 'absolute',
          top: '-100%',
          right: '-50%',
          width: '412%',
          // bg: 'rgba(0,0,0,0.3)',

          '> svg': {
            width: '100%',
            height: 'auto',
          },
        }}
      >
        <ProcessArrowVector arrow={3} />
      </div>
    </div>
  </>
);

const DesktopArrowContainer = styled.div`
  position: relative;
  pointer-events: none;
  display: none;
  ${media.lg`
    display: block;
  `}
  /* background: ${props => props.theme.colors.accent}; */
  > div {
    /* background: ${props => props.theme.colors.secondary}; */
  }
`;

const DesktopArrow = styled.div`
  position: absolute;
  /* default for most common; quarter arrows */
  height: calc(60% + 2px);
  width: 58%;
  border: 4px solid black;
  border-color: ${props => props.theme.colors.text};
`;

const DesktopArrowHead = styled.div`
  position: absolute;
  border: 12px solid transparent;
  /* transform: 'translateY(-50%)', */
`;

export const ProcessArrowsDesktop = () => (
  <>
    {/* One */}
    <DesktopArrowContainer sx={{ gridArea: 'arrow-1' }}>
      <DesktopArrow
        sx={{
          height: '128%',
          width: 'calc(130% + 2px)',
          bottom: '2%',
          left: '-2px',
          borderTopLeftRadius: '100%',
          borderRight: 'none',
          borderBottom: 'none',
        }}
      />
      {/* Arrowhead */}
      <DesktopArrowHead
        sx={{
          bottom: '0',
          left: '0',
          borderBottom: 'none',
          borderTopWidth: '24px',
          borderTopColor: 'text',
          transform: 'translateX(calc(-50%))',
        }}
      />
    </DesktopArrowContainer>
    <DesktopArrowContainer sx={{ gridArea: 'arrow-foo' }}>
      <DesktopArrow
        sx={{
          height: '130%',
          width: '130%',
          bottom: '0%',
          right: '40%',
          borderTopRightRadius: '100%',
          borderLeft: 'none',
          borderBottom: 'none',
        }}
      />
    </DesktopArrowContainer>

    {/* Two */}
    <DesktopArrowContainer sx={{ gridArea: 'arrow-foo' }}>
      <DesktopArrow
        sx={{
          bottom: 0,
          left: '2%',
          borderTopRightRadius: '100%',
          borderLeft: 'none',
          borderBottom: 'none',
        }}
      />
      <DesktopArrowHead
        sx={{
          top: '40%',
          left: '0',
          borderLeft: 'none',
          borderRightWidth: '24px',
          borderRightColor: 'text',
          transform: 'translateY(calc(-50%))',
        }}
      />
    </DesktopArrowContainer>

    {/* Three */}
    <DesktopArrowContainer sx={{ gridArea: 'arrow-2' }}>
      <DesktopArrow
        sx={{
          top: 0,
          right: '2%',
          borderBottomLeftRadius: '100%',
          borderTop: 'none',
          borderRight: 'none',
        }}
      />
      {/* Arrowhead */}
      <DesktopArrowHead
        sx={{
          top: '60%',
          right: '0',
          borderRight: 'none',
          borderLeftWidth: '24px',
          borderLeftColor: 'text',
          transform: 'translateY(calc(-50%))',
        }}
      />
    </DesktopArrowContainer>

    {/* Four */}
    <DesktopArrowContainer sx={{ gridColumn: 1, gridRow: 2 }}>
      <DesktopArrow
        sx={{
          width: 'calc(150% + 2px)',
          height: '148%',
          top: '2%',
          left: 'calc(50% - 2px)',
          borderBottomLeftRadius: '100%',
          borderBottomRadius: '100%',
          borderTop: 'none',
          borderRight: 'none',
        }}
      />
      {/* Arrowhead */}
      <DesktopArrowHead
        sx={{
          top: '0',
          left: '50%',
          borderTop: 'none',
          borderBottomWidth: '24px',
          borderBottomColor: 'text',
          transform: 'translateX(-50%)',
        }}
      />
    </DesktopArrowContainer>
    <DesktopArrowContainer sx={{ gridColumn: 4, gridRow: 3 }}>
      <DesktopArrow
        sx={{
          width: '200%',
          height: '50%',
          top: '0',
          right: '0',
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
        }}
      />
    </DesktopArrowContainer>
  </>
);
