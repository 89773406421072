import tool1 from '../assets/thinking/toolbox-1.png';
import tool2 from '../assets/thinking/toolbox-2.png';
import tool3 from '../assets/thinking/toolbox-3.png';
import tool4 from '../assets/thinking/toolbox-4.png';
import tool5 from '../assets/thinking/toolbox-5.png';
import tool6 from '../assets/thinking/toolbox-6.png';
import icon1 from '../assets/thinking/line-icon-1.svg';
import icon2 from '../assets/thinking/line-icon-2.svg';
import icon3 from '../assets/thinking/line-icon-3.svg';
import icon4 from '../assets/thinking/line-icon-4.svg';
import ProcessMobile from '../assets/process-mobile.svg';
import ProcessDesktop from '../assets/process-desktop.svg';
import processIcon1 from '../assets/processIcon/1.svg';
import processIcon2 from '../assets/processIcon/2.svg';
import processIcon3 from '../assets/processIcon/3.svg';
import chart1 from '../assets/thinkingHero/1.png';
import chart2 from '../assets/thinkingHero/2.png';
import chart3 from '../assets/thinkingHero/3.png';
import chart4 from '../assets/thinkingHero/4.png';
import chart5 from '../assets/thinkingHero/5.png';

const hero = {
  title: 'Users',
  headline: 'Desirability - Do users want or need the idea?',
  img: chart1,
};

const chart = [
  {
    title: 'Business',
    headline: 'Viability - Does the business need the idea?',
    img: chart2,
  },
  {
    title: 'Technology',
    headline: 'Feasibility - Is the technology ready for the idea?',
    img: chart3,
  },
  {
    title: 'Design Team',
    headline: 'We understand what our platform, business and users need by aligning to our design process',
    img: chart4,
  },
  {
    title: 'Design thinking',
    headline: 'designing the world’s most loved enterprise platform',
    img: chart5,
  },
];

const quote = {
  author: 'Ryan Parr, VP of Global Product Design',
  quote: '“Our design mantra — Celebrate our users in _everything_ we do.”',
};

const theNeed = {
  title: 'understanding the need',
  subheadline:
    'We use many products but how many do we truly love? Those that make us happy every time we interact with them, that genuinely help us, and are so thoughtful that it results in pure delight! The design team at Sprinklr thrives to make Sprinklr the world’s most loved enterprise product. Here is what we believe in:',
  blocks: [
    {
      headline: 'desirability',
      copy:
        'Like an artisan crafts his art with perfection, at every stage of design process we take pride in crafting things with utmost care, accuracy and thoughtfulness. We honour our skill with an uncompromising attention to detail.',
    },
    {
      headline: 'viability',
      copy:
        'We empathise with our users across an organisation, every role, every department: to understand why they do what they do and how they are currently doing it keeping in mind the evolving social space around us — the industry and the market; their daily routine and environment.',
    },
    {
      headline: 'feasibility',
      copy:
        'Like a friend consults with utmost thoughtfulness, we aid decision making and intelligently suggest the next steps. We do this by taking advantage of our expertise and technology across brand functions.',
    },
  ],
};

const ourProcess = {
  title: 'our approach',
  blocks: [
    {
      headline: 'User Sessions, research & workshops',
      copy:
        'Our goal is to discover what is important day to day for our users when using Sprinklr. Multi-day customer workshops allow designers to sit with users and solve design challenges in realtime.',
      glyph: icon1,
    },
    {
      headline: 'design iterations',
      copy:
        'A quick iteration process allows us to update designs focusing on usability, userflows and overall user experience',
      glyph: icon3,
    },

    {
      headline: 'Design Mock-Ups & animations',
      copy:
        'Hi fidelity representations of any design solution or user experience. Fully animated motion graphics are layered into the user experience and used for prototyping new experiences.',
      glyph: icon4,
    },
    {
      headline: 'User Testing',
      copy:
        'Scenarios gathered from our user research team are tested through a sequence of task oriented exercises in a process called Paper Prototyping',
      glyph: icon1,
    },
  ],
};

const processChart = {
  title: 'our process',
  images: [ProcessMobile, ProcessDesktop],
  blocks: [
    {
      headline: 'People',
      copy: 'Understand human needs and re-framing challenges in a human centric approach',
      gradient: '-90deg, rgb(255, 116, 156) 0%, rgb(64, 129, 255) 100%',
      glyph: processIcon1,
    },
    {
      headline: 'Design',
      copy:
        'Design is creating ideas in brainstorming sessions, and by adopting a hands-on approach in prototyping and testing',
      gradient: '90deg, rgb(255, 129, 75) 0%, rgb(255, 226, 75) 100%',
      glyph: processIcon2,
    },
    {
      headline: 'Result',
      copy:
        'Designs are realized as working product and iterated with feedback from the products. A solution-based approach to solving problems',
      gradient: '90deg, rgb(235, 238, 107) 0%, rgb(121, 201, 94) 100%',
      glyph: processIcon3,
    },
  ],
};

const processGrid = {
  blocks: [
    {
      headline: 'empathise',
      items: ['project managers', 'users', 'product owners', 'research', 'customers', 'market place'],
      activeCard: 0,
    },
    {
      headline: 'define',
      items: ['scope', 'personas', 'brief', 'process'],
      activeCard: 0,
    },
    {
      headline: 'ideate',
      items: ['brainstorm', 'information architecture', 'wireframe', 'design'],
      activeCard: 1,
    },
    {
      headline: 'prototype',
      items: ['user flow', 'journey', 'storyboard', 'interactions', 'transitions'],
      activeCard: 2,
    },
    {
      headline: 'test',
      items: ['scope', 'personas', 'brief', 'process'],
      activeCard: 3,
    },
  ],
};

const toolbox = {
  title: 'our toolbox',
  blocks: [
    {
      headline: 'space ui kit',
      copy:
        'The Space Design Kit provides a single source of truth for ui pattern, allowing designers to focus on creativity and not managing a design system.',
      glyph: tool5,
    },
    {
      headline: 'interactions library',
      copy:
        'Animatics and motion graphics provide hi-fidelity walkthroughs of complex workflows and illustrate the end-to-end user experience to developers and clients.',
      glyph: tool3,
    },
    {
      headline: 'centralized design version control',
      copy:
        'Our versioned and shareable design system provides a centralized location for designers, product and developers to come together and provide feedback or ask questions. ',
      glyph: tool2,
    },
    {
      headline: 'paper prototypes',
      copy:
        'A variation of usability testing, paper prototyping provides designers the opportunity to observe and listen to a user as they perform realistic tasks using a paper interface.',
      glyph: tool1,
      // (use this icon https://icons-for-free.com/marker+pen+sharpie+icon-1320165690718014706/)
    },
    {
      headline: 'design docs',
      copy:
        'A robust library of documentation provides context into our product and technical requirements and details how our design solution meet these requirements.',
      glyph: tool6,
    },
  ],
};

export default {
  hero,
  theNeed,
  chart,
  quote,
  processGrid,
  process: ourProcess,
  processChart,
  toolbox,
};
