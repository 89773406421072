/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import media from './media'
import GenericWrapper from './Wrapper'
import { ThreeColumnGridWrapper } from './TextSections'
import _DATA from '../data/thinking'
import { Arrowhead } from '../assets/VectorAssets'
import ProcessArrowVector from '../assets/ProcessArrows'
import BorderArrow, { BorderArrowWrapper } from './BorderArrow'
import {
  ProcessArrowsDesktop,
  ProcessArrowsDesktopSVG,
  ProcessArrowsMobile,
  ProcessDashedBorders,
} from './ProcessArrows'
import ThinkingCard, { ThinkingCardContainer } from './ThinkingCard'
import ThinkingProcessTextSection from './ProcessTextSection'
import ProcessGrid from './ProcessGrid'
import ProcessGridBlock from './ProcessGridBlock'

const ThinkingProcess = () => {
  const { processChart, processGrid, process } = _DATA
  const [activeCard, setActiveCard] = useState(null)
  const closeProcessCard = () => setActiveCard(null)

  return (
    <>
      {/* New Blocks Section */}
      <GenericWrapper
        onClick={() => {
          console.log('wrapper click')
        }}
      >
        <Styled.h2>{processChart.title}</Styled.h2>

        <ProcessGrid>
          {/* Arrows, Mobile */}
          <ProcessArrowsMobile />

          {/* Process Borders */}
          <ProcessDashedBorders />

          {/* Desktop Arrows */}
          <ProcessArrowsDesktop />
          {/* <ProcessArrowsDesktopSVG /> */}

          {/* Process Blocks */}
          {processGrid.blocks.map((x, i) => (
            <ProcessGridBlock
              title={x.headline}
              items={x.items}
              order={i + 1}
              key={i}
              setActiveCard={setActiveCard}
              activeCard={x.activeCard !== undefined ? x.activeCard : i}
              currentActiveCard={activeCard}
            />
          ))}

          {/* Thinking Cards */}
          <ThinkingCardContainer
            sx={{ opacity: `${activeCard !== null ? '1' : '0'}` }}
          >
            {process.blocks.map((block, i) => {
              const isActive = activeCard === i
              return (
                <ThinkingCard
                  active={isActive}
                  block={block}
                  onClose={closeProcessCard}
                  key={i}
                />
              )
            })}
          </ThinkingCardContainer>
        </ProcessGrid>
      </GenericWrapper>

      {/* Text Below */}
      <ThinkingProcessTextSection />
    </>
  )
}

export default ThinkingProcess
