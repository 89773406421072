/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import media from './media';

const FooBar = styled.span`
  width: 16px;
  height: 2px;
  background: white;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 8px);
`;

const ThinkingCardCloseButton = ({ onClick }) => (
  <div
    sx={{
      position: 'absolute',
      top: 0,
      right: 0,
      padding: '24px 32px',
    }}
    onClick={onClick}
  >
    {/* Close Button */}
    <div
      sx={{
        width: '12px',
        height: '12px',
        // bg: 'accent',
        display: 'block',
        position: 'relative',
      }}
    >
      <FooBar sx={{ transform: 'rotate(45deg)' }} />
      <FooBar sx={{ transform: 'rotate(-45deg)' }} />
    </div>
  </div>
);

export default ThinkingCardCloseButton;
