/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import media from './media'
import ThinkingCardCloseButton from './ThinkingCardCloseButton'

const ThinkingCardContainer = styled.div`
  /* mobile styles */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: initial;
  z-index: 99999;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.9);
  transition: opacity 150ms linear;

  ${media.lg`
    /* desktop/tablet ish */
    width: auto;
    position: absolute;
    display: flex;
    left: 50%;
    top: 240px;
    transform: translateX(-50%) translateY(-50%);
    pointer-events: none;

    /* reset stuff */
    right: initial;
    bottom: initial;
    background: transparent;
    /* go under the header */
    z-index: 9999;
    /* border: 1px dotted red; */
    max-width: 96vw;
    /* why is there a wierd edge? */
    

    /* > div {
      margin-right: 0.5rem;
    }
    > div:last-child {
      margin-right: 0;
    } */
  `}
`

const StyledThinkingCard = styled.div`
  /* mobile styles */
  position: absolute;
  top: 50vh;
  left: 50vw;
  right: 18px;
  padding: 24px 32px 32px;
  /* set an aggressive ht and cap it a max */
  height: calc(100vh - 48px);
  /* tallest */
  max-height: 390px;
  width: calc(100vw - (18px * 2));
  max-width: 340px;

  /* default state */
  opacity: 0;
  transform: translateY(0) translateX(-50%);
  transition: all 300ms ease-in;

  /* active styles */
  ${({ active }) =>
    active &&
    css`
      ${'' /* background: pink !important; */}
      transform: translateY(-50%) translateX(-50%);
      opacity: 1;
      pointer-events: all;
    `}

  ${media.lg`
    /* desktop/tablet ish */
    /* padding: 24px 32px 32px; */
    width: 100%;
    width: 340px;
    height: 460px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    /* pointer-events: none; */
    transition: opacity 300ms ease-in;
    /* reset stuff */
    position: relative;
    transform: none;
    left: initial;
    rigth: initial;
    top: initial;

    /* &:hover {
      transition: opacity 100ms ease-out;
    } */
  `}
`

const ThinkingCard = ({ active, onClose, block }) => {
  const { headline, copy } = block
  return (
    <StyledThinkingCard
      sx={{
        bg: 'text',
        color: 'background',
        opacity: `${active ? '1' : '0'}`,
      }}
      active={active}
    >
      <div sx={{ position: 'relative' }}>
        <Styled.h2 sx={{ pr: '18px' }}>{headline}</Styled.h2>
        <Styled.p sx={{ opacity: '0.7' }}>{copy}</Styled.p>
      </div>
      {/* Close Button */}
      <ThinkingCardCloseButton onClick={onClose} />
    </StyledThinkingCard>
  )
}

export default ThinkingCard
export { StyledThinkingCard, ThinkingCardContainer }
