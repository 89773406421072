/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import media from './media';

const fakeItems = ['project managers', 'users', 'product owners', 'research', 'customers', 'market place'];

const ProcessGridBlock = ({
  title = 'empathise',
  items = fakeItems,
  order,
  setActiveCard,
  activeCard,
  currentActiveCard,
}) => (
  <div
    role="button"
    tabIndex={0}
    sx={{
      variant: 'removeButtonStyles',
      textAlign: 'left',
      bg: theme => `${theme.colors.scale.tertiary[0]}`,
      px: '20px',
      py: '24px',
      padding: '24px 20px',
      gridArea: `block-${order}`,
      position: `${order === 5 ? 'relative' : ''}`,

      '> *': {
        pointerEvents: 'none',
      },

      '&:hover': {
        cursor: 'pointer',
      },

      h3: {
        color: theme => `${theme.colors.scale.tertiary[2]}`,
      },

      p: {
        color: 'background',
        mb: '0',
        opacity: '0.7',
      },
    }}
    // onMouseEnter={() => {
    //   console.log('moseOver: ', activeCard);
    //   setActiveCard(activeCard);
    // }}
    // onMouseLeave={() => {
    //   console.log('leave');
    //   setActiveCard(null);
    // }}
    onClick={() => {
      console.log('click: ', activeCard);
      setActiveCard(activeCard === currentActiveCard ? null : activeCard);
    }}
    onKeyPress={() => {
      console.log('handle keyboard event');
    }}
  >
    <Styled.h3 sx={{}}>{title}</Styled.h3>
    {items.map((x, i) => (
      <Styled.p key={i}>{x}</Styled.p>
    ))}
  </div>
);

ProcessGridBlock.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  order: PropTypes.number,
  setActiveCard: PropTypes.func,
  activeCard: PropTypes.number,
  currentActiveCard: PropTypes.number,
};

export default ProcessGridBlock;
